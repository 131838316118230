
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header } from '@/components/table/BpTable';
import { byKey, Order } from '@/utils/array';
import { Category, Recording, useVideoStore, Video, Webinar } from '@/stores/video';
import { computed, ref } from '@vue/reactivity';
import { copyToClipboard } from '@/utils/navigator';
import { currentLanguage, currentLanguageISOString, getTranslated } from '@/translation';
import { defineComponent, watch } from 'vue';
import { KeyOf } from '@/utils/object';
import { removeHTML, translatedUnitEnumeration } from '@/utils/string';
import { toDate } from '@/components/table/BpTable';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import BpToast from '@/components/toast/BpToasts';

export default defineComponent({
  name: 'admin.video-overview',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const lang = computed(() => currentLanguage.value === 'de' ? 'de' : 'en');

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value, store.isLoading()], () => {
      if (!store.loaded || store.isLoading()) { return }
      headers.value = [
        {
          _id: 'name.' + currentLanguageISOString(),
          name: $gettext('Name'),
          width: '2fr',
          icon: (video: Video) => 'webinar' in video
            ? 'video'
            : 'youtube_id' in video
              ? 'clapperboard-play'
              : expanded.value?.has(video._id) && store.hasChildren(video._id) ? ['far', 'folder-open'] : ['far', 'folder'],
          transform: (video: Video) => video.name[lang.value],
        },
        {
          _id: 'featured',
          name: $gettext('Featured'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'star', color: ['yellow', 500] },
              detect: (_video: Video, featured: Video['featured']) => featured,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: ['far', 'star'], color: 'var(--theme-text-disabled)' },
              detect: (_video: Video, featured: Video['featured']) => !featured,
            }
          ],
          notSortable: true,
          textAlign: 'center',
          icon: (_video: Video, featured: Video['featured']) => featured
            ? { icon: 'star', color: ['yellow', 500] }
            : { icon: ['far', 'star'], color: 'var(--theme-text-disabled)' },
          transform: () => '',
        },
        {
          _id: 'date',
          name: $gettext('Date'),
          transform: toDate,
        },
        {
          _id: 'description.' + currentLanguageISOString(),
          name: $gettext('Description'),
          width: '1.25fr',
          tooltip: (video: Video) => video.description[lang.value],
          transform: (video: Video) => removeHTML(video.description[lang.value]),
        },
        {
          _id: 'hint.' + currentLanguageISOString(),
          name: $gettext('Hint'),
          hidden: true,
          tooltip: (video: Video) => video.hint[lang.value],
          transform: (video: Video) => removeHTML(video.hint[lang.value]),
        },
        {
          _id: 'tags',
          name: $gettext('Tags'),
          filter: store.getTags().map(
            tag => ({
              ...tag,
              icon: 'hashtag',
              detect: (video: Video) => video.tags.includes(tag._id),
            })
          ),
        },
        {
          _id: 'slug',
          name: $gettext('URL slug'),
        },
        {
          _id: 'webinar.token',
          canCopy: true,
          name: $gettext('Token'),
          transform: (video: Video) => 'webinar' in video ? video.webinar.token : '',
        },
      ];
    }, { immediate: true })


    ///-------------------------------------------------------------------
    /// FEATURED WEBINARS
    ///-------------------------------------------------------------------

    const highlightActiveWebinars = (video: Video) => {
      return 'webinar' in video && video.webinar.active ? 'bp-video-overview__active-webinar' : undefined;
    }

    ///-------------------------------------------------------------------
    /// DIRECT LINK
    ///-------------------------------------------------------------------

    function copyDirectLink(video: Recording | Webinar) {
      copyToClipboard(window.location.origin + '/#/video/' + video.slug);
    }

    ///-------------------------------------------------------------------
    /// UPDATE
    ///-------------------------------------------------------------------

    function updateVideo(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      const video = store.getById(id);
      if (!video) {
        return;
      }
      const isWebinar = 'webinar' in video;
      const isRecording = 'youtube_id' in video;
      router.push({
        name: isWebinar ? 'admin.video.webinar.edit' : (isRecording ? 'admin.video.recording.edit' : 'admin.video.category.edit'),
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      categories: Category[],
      recordings: Recording[],
      webinars: Webinar[],
    }>({
      categories: [],
      recordings: [],
      webinars: [],
    })
    const confirmSelectedIds = ref<string[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      confirmSelectedIds.value = ids;
      const videos = store.getFlatSubtree(ids);
      confirmDelete.value.categories = (videos.filter(video => video.doc_type === 'video_category') as Category[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmDelete.value.recordings = (videos.filter(video => video.doc_type === 'video_recording') as Recording[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmDelete.value.webinars = (videos.filter(video => video.doc_type === 'video_webinar') as Webinar[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteVideo() {
      const categoryIDs = confirmDelete.value.categories.map(category => category._id);
      const recordingIDs = confirmDelete.value.recordings.map(recording => recording._id);
      const webinarIDs = confirmDelete.value.webinars.map(webinar => webinar._id);
      const response = await store.delete([
        ...categoryIDs,
        ...recordingIDs,
        ...webinarIDs,
      ]);

      if (response?.success) {
        const categorys = response.data?.videos.filter(doc => doc.doc_type === 'video_category') || [];
        const recordings = response.data?.videos.filter(doc => doc.doc_type === 'video_recording') || [];
        const webinars = response.data?.videos.filter(doc => doc.doc_type === 'video_webinar') || [];

        if (categorys && recordings && webinars) {
          if (categorys.length === categoryIDs.length && recordings.length === recordingIDs.length && webinars.length === webinarIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                categorys: [categoryIDs.length, categorys.length],
                recordings: [recordingIDs.length, recordings.length],
                webinars: [webinarIDs.length, webinars.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                categorys.length + recordings.length + webinars.length,
                {
                  list: translatedUnitEnumeration({
                    categorys: [categoryIDs.length, categorys.length],
                    recordings: [recordingIDs.length, recordings.length],
                    webinars: [webinarIDs.length, webinars.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                categorys: [categoryIDs.length, categorys.length],
                recordings: [recordingIDs.length, recordings.length],
                webinars: [webinarIDs.length, webinars.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  categorys.length + recordings.length + webinars.length,
                  {
                    list: translatedUnitEnumeration({
                      categorys: [categoryIDs.length, categorys.length],
                      recordings: [recordingIDs.length, recordings.length],
                      webinars: [webinarIDs.length, webinars.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      categorys: [categoryIDs.length, categorys.length],
                      recordings: [recordingIDs.length, recordings.length],
                      webinars: [webinarIDs.length, webinars.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            categorys: categoryIDs.length,
            recordings: recordingIDs.length,
            webinars: webinarIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            categorys: categoryIDs.length,
            recordings: recordingIDs.length,
            webinars: webinarIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        categories: [],
        recordings: [],
        webinars: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// DRAGGING
    ///-------------------------------------------------------------------

    function dragenter(targetId: string, sourceId: string) {
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-video-overview__highlight'))
      if (targetId !== sourceId && store.getValidParentCategories(sourceId).map(category => category._id).includes(targetId)) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.add('bp-video-overview__highlight');
      }
    }

    function dragend(targetId: null, sourceId: string) {
      if (targetId !== sourceId) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.remove('bp-video-overview__highlight');
      }
    }

    function drop(parent: string, id: string) {
      // Remove highlight styling
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-video-overview__highlight'))

      // Move files
      const current = store.getById(id);
      if (parent !== id && store.getValidParentCategories(id).map(category => category._id).includes(parent) && current && current.parent_id !== parent) {
        store.move(id, parent)
      }
    }

    ///-------------------------------------------------------------------
    /// SORTING
    ///-------------------------------------------------------------------

    const sortKey = ref<KeyOf<Video>>('order');
    const sortOrder = ref<Order>('asc');

    function sort(sorting: Map<keyof Video, Order>) {
      const [key, order] = sorting.entries().next().value;
      sortKey.value = key;
      sortOrder.value = order;
    }

    ///-------------------------------------------------------------------
    /// EXPANDED
    ///-------------------------------------------------------------------

    const expanded = ref<Set<string>>();

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading() || expanded.value) { return }
      expanded.value = new Set(store.getCategories().filter(el => store.hasChildren(el._id)).map(category => category._id))
    }, { immediate: true });

    function updateExpanded(value: Set<string>) {
      expanded.value = value;
    }

    ///-------------------------------------------------------------------
    /// QUERY
    ///-------------------------------------------------------------------

    function getQuery(selection: Set<string>) {
      const id = selection.size === 1 ? Array.from(selection)[0] : undefined;
      if (!id) {
        return {};
      }
      return store.isCategory(id) ? { parent: id } : {}
    }

    ///-------------------------------------------------------------------
    /// EDIT PARTICIPANT WINDOW
    ///-------------------------------------------------------------------

    const editParticipantsWindow = ref(false)
    const editParticipantsWebinar = ref();
    const { errors: editParticipantsErrors, setError } = useFormErrors();

    function showEditParticipantsWindow(webinar: Webinar) {
      editParticipantsWindow.value = true;
      editParticipantsWebinar.value = webinar;
    }

    async function saveParticipants() {
      const response = await store.update(editParticipantsWebinar.value);
      if (response && response.success && response.data) {
        BpToast.show({
          color: 'green',
          title: $gettext('Participants updated'),
          content: $gettext('The list of participants of the webinar "%{webinar}" was updated successfully.', { webinar: getTranslated(response.data.name) }),
          icon: 'circle-check',
        });
      } else if (response && response.error) {
        BpToast.show({
          color: 'red',
          title: $gettext('Participants update failed'),
          content: $gettext('The list of participants could not be updated: %{error}', { error: response.error }),
          icon: 'triangle-exclamation',
        });
      }
      editParticipantsWindow.value = false;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmDelete,
      confirmSelectedIds,
      copyDirectLink,
      deleteVideo,
      dragend,
      dragenter,
      drop,
      editParticipantsErrors,
      editParticipantsWebinar,
      editParticipantsWindow,
      expanded,
      getQuery,
      getTranslated,
      headers,
      highlightActiveWebinars,
      router,
      saveParticipants,
      setError,
      showDeleteConfirmation,
      showEditParticipantsWindow,
      sort,
      sortKey,
      sortOrder,
      store,
      translatedUnitEnumeration,
      updateExpanded,
      updateVideo,
    }
  }
})

