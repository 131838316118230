import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bp-video-overview" }
const _hoisted_2 = { class: "bp-video-overview__table-prefix" }
const _hoisted_3 = { class: "bp-video-overview__table-prefix" }
const _hoisted_4 = { class: "bp-video-overview__table-prefix" }
const _hoisted_5 = { class: "bp-video-overview__table-prefix" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "bp-video-overview__confirmation-hint"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "bp-video-overview__confirmation-hint"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  key: 0,
  class: "bp-video-overview__confirmation-hint"
}
const _hoisted_12 = {
  key: 3,
  class: "bp-video-overview__confirmation-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-video-overview__header",
      image: "/static/background/app_background_video.jpg",
      icon: ['fad','video']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Video overview")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_table, {
      data: _ctx.store.getTree(_ctx.sortKey, _ctx.sortOrder),
      "default-sorting": _ctx.sortKey,
      expanded: _ctx.expanded,
      headers: _ctx.headers,
      loading: _ctx.store.isLoading(),
      "row-class": _ctx.highlightActiveWebinars,
      searchable: false,
      "fill-height": "",
      "draggable-rows": "",
      "onRows:dragend": _ctx.dragend,
      "onRows:dragenter": _ctx.dragenter,
      "onRows:drop": _ctx.drop,
      "onUpdate:expanded": _ctx.updateExpanded,
      onSort: _ctx.sort
    }, {
      prefix: _withCtx(({ selection }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_bp_icon, { icon: "check" }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> selected videos",
            "translate-n": selection.size,
            "translate-params": { count: selection.size }
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" selected video ")
            ])),
            _: 2
          }, 1032, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_bp_icon, { icon: ['far', 'folder'] }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> categories",
            "translate-n": _ctx.store.getCategories().length,
            "translate-params": { count: _ctx.store.getCategories().length }
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" category ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_bp_icon, { icon: "clapperboard-play" }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> recordings",
            "translate-n": _ctx.store.getVideos({type: 'recording'}).length,
            "translate-params": { count: _ctx.store.getVideos({type: 'recording'}).length }
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" recording ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_bp_icon, { icon: "video" }),
          _createVNode(_component_translate, {
            "translate-plural": "<strong>%{count}</strong> webinars",
            "translate-n": _ctx.store.getVideos({type: 'webinar'}).length,
            "translate-params": { count: _ctx.store.getVideos({type: 'webinar'}).length }
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("strong", null, "%{count}", -1),
              _createTextVNode(" webinar ")
            ])),
            _: 1
          }, 8, ["translate-n", "translate-params"])
        ])
      ]),
      toolbar: _withCtx(({ selection }) => [
        _createVNode(_component_bp_button, {
          icon: "arrows-rotate",
          color: "yellow",
          onClick: _ctx.store.reload
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Refresh")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'folder-plus'],
          color: "green",
          to: { name: 'admin.video.category.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("New category")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_bp_button, {
          icon: [
          {
            icon: ['far', 'clapperboard'],
          },
          {
            icon: ['fad', 'square-plus'],
            secondaryOpacity: 0,
            transform: 'shrink-4 down-2',
          },
        ],
          color: "green",
          to: { name: 'admin.video.recording.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("New recording")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'video-plus'],
          color: "green",
          to: { name: 'admin.video.webinar.new', query: _ctx.getQuery(selection) }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("New webinar")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["to"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'pencil'],
          color: "light-blue",
          disabled: selection.size !== 1,
          onClick: ($event: any) => (_ctx.updateVideo(Array.from(selection)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Edit")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"]),
        _createVNode(_component_bp_button, {
          style: {"margin-left":"auto"},
          icon: ['far', 'trash-can'],
          color: "red",
          disabled: selection.size === 0,
          onClick: ($event: any) => (_ctx.showDeleteConfirmation(Array.from(selection)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Delete")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      actions: _withCtx(({ item }) => [
        (_ctx.store.isFeaturedWebinar(item._id) && !item.webinar.active)
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              key: 0,
              icon: "user-pen",
              onClick: ($event: any) => (_ctx.showEditParticipantsWindow(item))
            }, null, 8, ["onClick"])), [
              [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Edit participants'))]
            ])
          : _createCommentVNode("", true),
        (_ctx.store.isFeaturedWebinar(item._id) && item.webinar.active)
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              key: 1,
              icon: "messages",
              onClick: ($event: any) => (_ctx.router.push({ name: 'video.play', params: { slug: item.slug }}))
            }, null, 8, ["onClick"])), [
              [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Moderate webinar chat'))]
            ])
          : _createCommentVNode("", true),
        (_ctx.store.isFeaturedWebinar(item._id) && !item.webinar.active)
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              key: 2,
              icon: "play",
              onClick: ($event: any) => (_ctx.store.startWebinar(item))
            }, null, 8, ["onClick"])), [
              [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Start webinar'))]
            ])
          : _createCommentVNode("", true),
        (_ctx.store.isFeaturedWebinar(item._id) && item.webinar.active)
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              key: 3,
              icon: "stop",
              onClick: ($event: any) => (_ctx.store.stopWebinar(item))
            }, null, 8, ["onClick"])), [
              [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Stop webinar'))]
            ])
          : _createCommentVNode("", true),
        (['video_recording', 'video_webinar'].includes(item.doc_type))
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              key: 4,
              icon: "link",
              onClick: ($event: any) => (_ctx.copyDirectLink(item))
            }, null, 8, ["onClick"])), [
              [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Copy video link'))]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_bp_icon, {
          icon: ['far', 'pencil'],
          onClick: ($event: any) => (_ctx.updateVideo(item._id))
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, _ctx.actionTooltip(['video_recording', 'video_webinar'].includes(item.doc_type) ? _ctx.$gettext('Edit video') : _ctx.$gettext('Edit category'))]
        ]),
        _withDirectives(_createVNode(_component_bp_icon, {
          icon: ['far', 'trash-can'],
          color: "red",
          onClick: ($event: any) => (_ctx.showDeleteConfirmation(item._id))
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, _ctx.actionTooltip(['video_recording', 'video_webinar'].includes(item.doc_type) ? _ctx.$gettext('Delete video') : _ctx.$gettext('Delete category'))]
        ])
      ]),
      _: 1
    }, 8, ["data", "default-sorting", "expanded", "headers", "loading", "row-class", "onRows:dragend", "onRows:dragenter", "onRows:drop", "onUpdate:expanded", "onSort"]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { list: _ctx.translatedUnitEnumeration({
          categories: _ctx.confirmDelete.categories.length,
          recordings: _ctx.confirmDelete.recordings.length,
          webinars: _ctx.confirmDelete.webinars.length,
        }) }
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createTextVNode("Do you really want to delete "),
            _createElementVNode("strong", null, "%{list}", -1),
            _createTextVNode("?")
          ])),
          _: 1
        }, 8, ["translate-params"]),
        (_ctx.confirmDelete.categories.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_6, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} categories",
                      "translate-n": _ctx.confirmDelete.categories.length,
                      "translate-params": { count: _ctx.confirmDelete.categories.length }
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" %{count} category ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.categories, (category) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: category._id
                  }, [
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString('name' in category ? _ctx.getTranslated(category.name) : ''), 1),
                      (!_ctx.confirmSelectedIds.includes(category._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.recordings.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} recordings",
                      "translate-n": _ctx.confirmDelete.recordings.length,
                      "translate-params": { count: _ctx.confirmDelete.recordings.length }
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode(" %{count} recording ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.recordings, (recording) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: recording._id
                  }, [
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString('name' in recording ? _ctx.getTranslated(recording.name) : ''), 1),
                      (!_ctx.confirmSelectedIds.includes(recording._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.webinars.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_10, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, [
                    _createVNode(_component_translate, {
                      "translate-plural": "%{count} webinars (incl. chats)",
                      "translate-n": _ctx.confirmDelete.webinars.length,
                      "translate-params": { count: _ctx.confirmDelete.webinars.length }
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode(" %{count} webinar (incl. chat) ")
                      ])),
                      _: 1
                    }, 8, ["translate-n", "translate-params"])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.webinars, (webinar) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: webinar._id
                  }, [
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString('name' in webinar ? _ctx.getTranslated(webinar.name) : ''), 1),
                      (!_ctx.confirmSelectedIds.includes(webinar._id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, " *"))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmSelectedIds.length < Object.values(_ctx.confirmDelete).flat().length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
              _cache[22] || (_cache[22] = _createTextVNode("* ")),
              _createVNode(_component_translate, {
                tag: "em",
                style: {"font-weight":"normal"},
                "translate-plural": "These <strong>%{list}</strong> are included in the selection and are therefore also deleted.",
                "translate-n": Object.values(_ctx.confirmDelete).flat().filter(version => !_ctx.confirmSelectedIds.includes(version._id)).length,
                "translate-params": { list: _ctx.translatedUnitEnumeration({
          categories: _ctx.confirmDelete.categories.filter(category => !_ctx.confirmSelectedIds.includes(category._id)).length,
          recordings: _ctx.confirmDelete.recordings.filter(recording => !_ctx.confirmSelectedIds.includes(recording._id)).length,
          webinars: _ctx.confirmDelete.webinars.filter(webinar => !_ctx.confirmSelectedIds.includes(webinar._id)).length,
        }, { includeCount: false }) }
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("This "),
                  _createElementVNode("strong", null, "%{list}", -1),
                  _createTextVNode(" is included in the selection and is therefore also deleted.")
                ])),
                _: 1
              }, 8, ["translate-n", "translate-params"])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _ctx.deleteVideo
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      "model-value": _ctx.editParticipantsWindow,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.editParticipantsWindow = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [
            _createTextVNode("Edit participants")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          type: "save",
          errors: _ctx.editParticipantsErrors,
          action: _ctx.saveParticipants
        }, null, 8, ["errors", "action"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bp_select, {
          indent: 1.125,
          label: _ctx.$gettext('Participants'),
          text: _ctx.$gettext('The selected language for the participants will be the language for the email reminders.'),
          modelValue: _ctx.editParticipantsWebinar.webinar.participants,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editParticipantsWebinar.webinar.participants) = $event)),
          onChangeValid: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setError('webinar.participants', _ctx.$gettext('Participants'), $event, _ctx.editParticipantsErrors))),
          "create-new-on-enter": "",
          clearable: "",
          multiple: ""
        }, null, 8, ["label", "text", "modelValue"])
      ]),
      _: 1
    }, 8, ["model-value"])
  ]))
}